<template>
    <div class="retrieve-retrievePwd">
        <!--        <div class="navbar-wrapper">-->
        <!--            <Navbar />-->
        <!--        </div>-->
        <div class="retrieve-pwd-container">
            <div class="retrieve-pwd-main">
                <h2 class="title" style="margin-top: 0">找回密码</h2>

                <div class="retrieve-pwd">
                    <el-input v-model="retrievePwd.phone" style="width: 350px; height: 40px;">
                        <template v-slot:prepend>
                            <span class="input-prepend">手机号</span>
                        </template>
                    </el-input>
                    <el-input v-model="retrievePwd.password" show-password
                              style="width: 350px; height: 40px;" type="password">
                        <template v-slot:prepend>
                            <span class="input-prepend">新密码</span>
                        </template>
                    </el-input>
                    <el-input v-model="retrievePwd.confirmPassword" show-password
                              style="width: 350px; height: 40px;" type="password">
                        <template v-slot:prepend>
                            <span class="input-prepend">确认密码</span>
                        </template>
                    </el-input>
                    <el-input v-model="retrievePwd.verificationCode" style="width: 350px; height: 40px;">
                        <template v-slot:prepend>
                            <span class="input-prepend">验证码</span>
                        </template>
                        <template v-slot:suffix>
                            <el-button :disabled="!canGetCode" class="get-verify-code-btn" link
                                       @click="getVerificationCode">
                                {{ buttonText }}
                            </el-button>
                        </template>
                    </el-input>

                </div>
                <div>
                    <el-button class="retrieve-pwd-confirm-button" style="width: 150px" @click="registerButton">
                        找回密码
                    </el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'

const retrievePwd = reactive({
    phone: '',
    password: '',
    confirmPassword: '',
    verificationCode: ''
})

// 验证码时间
const canGetCode = ref(true)
const buttonText = ref('获取验证码')
let timer = null
const getVerificationCode = async () => {
    if (timer) return

    canGetCode.value = false
    let countdown = 60
    buttonText.value = `${countdown}秒`

    timer = setInterval(() => {
        countdown -= 1
        buttonText.value = `${countdown}秒`
        if (countdown <= 0) {
            clearInterval(timer)
            timer = null
            canGetCode.value = true
            buttonText.value = '获取验证码'
        }
    }, 1000)

    // console.log('发送验证码到手机')
    const requestParams = {
        phone: retrievePwd.phone,
        type: 2
    }
    try {
        await apiService.generateVerifyCode(requestParams)
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}

// 找回密码
const router = useRouter()
const registerButton = async () => {
    // 校验 1. 确认密码
    // 校验确认密码

    if (retrievePwd.password !== retrievePwd.confirmPassword) {
        alertService.showError('两次密码不一致')
        return
    }
    // 参数
    const requestParams = {
        phone: retrievePwd.phone,
        newPassword: retrievePwd.password,
        code: retrievePwd.verificationCode
    }
    try {
        apiService.retrievePassword(requestParams)
        alertService.showSuccess('密码找回成功')
        // 跳转登录界面
        router.push('/login')
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}
</script>

<style>
.retrieve-retrievePwd {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .retrieve-pwd-container {
        width: 50%;
        display: flex;
        flex-direction: column; /* 修改为列方向布局 */
        align-items: center;
        border-radius: 15px;
        border: 1px solid #002f5a;
        box-shadow: 0 0 10px 2px #002f5a;
        padding: 60px 30px;
        background-color: #fff;

        .retrieve-pwd-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .retrieve-pwd {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .input-prepend {
                    display: inline-block;
                    width: 80px; /* 根据最长的前缀文字调整宽度 */
                    text-align: center;
                }

                .get-verify-code-btn {
                    color: #002f5a;
                    cursor: pointer;
                }
            }

            .retrieve-pwd-confirm-button {
                margin-top: 20px;
                background: #002f5a;
                color: #fff;
                cursor: pointer;

            }
        }
    }
}


</style>
