<template>
    <div v-show="isVisible" class="dialog-overlay">
        <div class="dialog">
            <h3 class="dialog-title">兑换码</h3>
            <input
                v-model="redeemCode"
                class="dialog-input"
                placeholder="请输入兑换码"
                type="text"
            />
            <button class="dialog-button" @click="redeem">兑换</button>
            <button class="dialog-button dialog-close-button" @click="close">关闭</button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'

const props = defineProps({ visible: Boolean })
const emit = defineEmits(['update:visible', 'redeem'])

const isVisible = ref(props.visible)
const redeemCode = ref('')

watch(
    () => props.visible,
    (newVal) => {
        isVisible.value = newVal
    }
)

const close = () => {
    isVisible.value = false
    emit('update:visible', false)
}

const router = useRouter()
const redeem = async () => {
    if (!redeemCode.value) {
        alertService.showError('兑换码不能为空')
        return
    }
    const requestParams = {
        code: redeemCode.value
    }
    try {
        await apiService.activateCoupons(requestParams)
        alertService.showSuccess('兑换成功！')
        close()
        // if (true) {
        //
        // } else {
        //     throw new Error('兑换失败')
        // }
    } catch (error) {
        if (error.response?.data?.code === 401) {
            alertService.showInfo('用户未登录')
            // router.push('/login')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}
</script>

<style>
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px 2px #002f5a;
}

.dialog-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.dialog-input {
    width: 90%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dialog-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    background-color: #002f5a;
    color: white;
    cursor: pointer;
}

.dialog-close-button {
    background-color: #ccc;
}
</style>
