<template>
    <div class="recharge-container">
        <h2> 产品定价 </h2>
        <div class="user-container">
            <span class="user-container-account">充值帐号：{{ phone }}</span>
            <span class="user-container-balance">当前余额：{{ balance }}词</span>
        </div>
        <el-card class="recharge-card">
            <el-tabs class="recharge-card-tabs" type="border-card">
                <el-tab-pane label="降AI率套餐包">
                    <component :is="GoodsList"></component>
                </el-tab-pane>
                <el-tab-pane active-color="#ff7043" label="写作辅导">
                    <component :is="CustomService"></component>
                </el-tab-pane>
                <!--                <el-tab-pane label="Role">Role</el-tab-pane>-->
                <!--                <el-tab-pane label="Task">Task</el-tab-pane>-->
            </el-tabs>

        </el-card>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import GoodsList from '@/components/GoodsList.vue'
import CustomService from '@/components/CustomService.vue'

const phone = ref(null)
const balance = ref(0)

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        phone.value = response.data.userInfo.phone
        balance.value = response.data.userInfo.balance
    } catch (error) {
        if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
            alertService.showInfo('用户未登录')
        } else {
            alertService.showError(error.response?.data?.message || '发生未知错误！')
        }
    }
}

onMounted(() => {
    fetchDataUserInfo()
    // fetchProduct()
})
</script>

<style lang="scss">
.recharge-container {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .user-container {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .user-container-account {
            margin-right: 30px;
        }
    }

    .recharge-card {
        width: 80%;
        height: 80%;

        .el-card__body {
            height: 90%;
        }

        .recharge-card-tabs {
            height: 100%;

            .recharge-card-tabs {
                --el-tab-item-hover-color: #ff7043;
            }
        }

    }

}
</style>