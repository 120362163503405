<template>
    <div class="goods-list">
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                350词
                <span class="good-detail-feature">免费</span>
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">0</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger" @click="showCRCode">
                添加微信
            </el-button>
            <ul class="good-detail-introduce-list">

                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    新用户免费获得200词体验额度
                </li>
                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    添加工作室微信即可免费领取150词体验额度
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                500词
                <!--                <span class="good-detail-feature">免费体验</span>-->
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">49.88</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger"
                       @click="doRecharge(100003, 49.88, '字数购买')">立即购买
            </el-button>
            <ul class="good-detail-introduce-list">

                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    单词0.1元/词
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                1000词
                <!--                <span class="good-detail-feature">免费体验</span>-->
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">97.88</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger"
                       @click="doRecharge(100004, 97.88, '字数购买')">立即购买
            </el-button>
            <ul class="good-detail-introduce-list">
                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    单价0.098元/词
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                2000词
                <!--                <span class="good-detail-feature">免费体验</span>-->
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">188.88</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger"
                       @click="doRecharge(100005, 188.88, '字数购买')">立即购买
            </el-button>
            <ul class="good-detail-introduce-list">

                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    单价0.094元/词
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                5000词
                <!--                <span class="good-detail-feature">免费体验</span>-->
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">468.88</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger"
                       @click="doRecharge(100006, 468.88, '字数购买')">立即购买
            </el-button>
            <ul class="good-detail-introduce-list">

                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    单价0.093元/词
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-card class="good-detail" shadow="never">
            <h2 class="good-detail-title">
                10000词
                <!--                <span class="good-detail-feature">免费体验</span>-->
            </h2>
            <p class="good-detail-info">
                <span class="currency-type">¥</span>
                <span class="goods-price">888.88</span>
                <!--                <span class="expiration-date">CNY</span>-->
            </p>
            <el-button class="buy-now" round style="width: 100%; font-size: 20px" type="danger"
                       @click="doRecharge(100007, 888.88, '字数购买')">立即购买
            </el-button>
            <ul class="good-detail-introduce-list">

                <li class="good-detail-introduce-bold">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    单价0.089元/词
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    有效降低AI率至0%
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    保障原文的连贯性、逻辑性和可读性、不改变文章原意
                </li>
                <li class="good-detail-introduce">
                    <el-icon color="#409efc">
                        <Promotion />
                    </el-icon>
                    智能识别修改拼写和语法错误
                </li>
            </ul>
        </el-card>
        <el-dialog v-model="qrcodeVisible" :show-close="false" :style="{height: qrcodeSize}" :width="qrcodeSize - 15"
                   center class="qrcode-dialog" draggable>
            <el-image :fit="'fill'" :src="customerServiceUrl" class="custom-service-qrcode"
                      style="width: 300px; height: 300px"
                      visiable="false" />
        </el-dialog>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import { Promotion } from '@element-plus/icons-vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import customerServiceUrl from '@/assets/WechatIMG17.jpg'

const qrcodeVisible = ref(false)
const qrcodeSize = 350

function showCRCode() {
    qrcodeVisible.value = true
}

async function doRecharge(productId, rechargeAmount, subject) {
    const requestParams = {
        productId: productId,
        totalAmount: String(rechargeAmount),
        subject: subject
    }
    try {
        const response = await apiService.createPaymentLink(requestParams)
        const url = response.data.paymentUrl
        // 使用window.open方法在新窗口中打开url
        window.open(url, '_blank')
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}
</script>
<style lang="scss" scoped>
.goods-list {
    width: 100%;
    display: flex;
    flex-direction: row;

    .good-detail {
        flex: 1;
        margin-left: 3px;
        margin-right: 3px;

        .good-detail-feature {
            display: inline-block;
            background-color: rgba(255, 99, 71, 0.1); /* 浅色背景 */
            color: #ff6347; /* 字体颜色 */
            padding: 5px 15px; /* 内边距 */
            border-radius: 20px; /* 圆角 */
            font-size: 14px; /* 字体大小 */
            font-weight: bold; /* 字体加粗 */
        }

        .good-detail-info {
            .currency-type {
                font-size: 14px;
                color: #ff614f;
                font-weight: bold;
            }

            .goods-price {
                font-size: 20px;
                color: #ff614f;
                font-weight: bold;
            }

            .expiration-date {
                font-size: 12px;
                color: #8b7373;
                font-weight: bold;
            }
        }

        .good-detail-introduce-list {
            padding: 0;

            .good-detail-introduce,
            .good-detail-introduce-bold {
                display: flex;
                align-items: center;
                margin-bottom: 8px; /* 间距调整 */
                font-size: 14px;
                letter-spacing: 1px;
                font-family: "微软雅黑";

                .el-icon {
                    margin-right: 10px; /* 图标和文本的间距 */
                }
            }

        }

        .good-detail-introduce-bold {
            font-weight: bold;
        }
    }
}
</style>