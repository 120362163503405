<script setup>

</script>

<template>
    <div class="dummy"></div>
    <p class="ICP-container"> ICP证: <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2024115537号-1</a></p>
</template>

<style scoped>
.dummy {
    border-top: 2px solid #002f5a;
}

.ICP-container {
    font-size: 13px;
    margin-left: 5%;
    height: 30px;

    a {
        text-decoration: none; /* 去掉下划线 */
        color: black; /* 设置链接颜色为黑色 */
    }

}
</style>